import _default from './json/default.json';
import leftRight from './json/left-right.json';
import upDown from './json/up-down.json';
import circle from './json/circle.json';
import diagonal from './json/diagonal.json';
import corners from './json/corners.json';
import tie from './json/tie.json';

import leftRightIcon from './img/icon_anim_0.svg';
import upDownIcon from './img/icon_anim_1.svg';
import circleIcon from './img/icon_anim_2.svg';
import diagonalIcon from './img/icon_anim_3.svg';
import cornersIcon from './img/icon_anim_4.svg';
import tieIcon from './img/icon_anim_5.svg';

export enum EnumAnimations {
  DEFAULT = 0,
  LEFT_RIGHT,
  UP_DOWN,
  CIRCLE,
  DIAGONAL,
  CORNERS,
  TIE,
}

export const animationDescs = {
  [EnumAnimations.LEFT_RIGHT]: 'дискомфорт в глазах',
  [EnumAnimations.UP_DOWN]: 'усталость глаз',
  [EnumAnimations.CIRCLE]: 'сухость глаз',
  [EnumAnimations.DIAGONAL]: 'жжение в глазах',
  [EnumAnimations.CORNERS]: 'боль или резь в глазах',
  [EnumAnimations.TIE]: 'покраснение глаз',
};

export const buttonIcons = {
  [EnumAnimations.LEFT_RIGHT]: leftRightIcon,
  [EnumAnimations.UP_DOWN]: upDownIcon,
  [EnumAnimations.CIRCLE]: circleIcon,
  [EnumAnimations.DIAGONAL]: diagonalIcon,
  [EnumAnimations.CORNERS]: cornersIcon,
  [EnumAnimations.TIE]: tieIcon,
};

export const animations = {
  [EnumAnimations.DEFAULT]: _default,
  [EnumAnimations.LEFT_RIGHT]: leftRight,
  [EnumAnimations.UP_DOWN]: upDown,
  [EnumAnimations.CIRCLE]: circle,
  [EnumAnimations.DIAGONAL]: diagonal,
  [EnumAnimations.CORNERS]: corners,
  [EnumAnimations.TIE]: tie,
};
