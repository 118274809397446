import Flex from 'Components/Flex';
import Box from 'Components/Box';
import React from 'react';
import styled from 'styled-components';
import {
  StyledTitle,
  StickyTextBlock,
  HorizontalScrollable,
  ListCard,
  ListBullet,
  listCardDefaultTheme,
} from './styled';
import { HandText, Text } from 'Components/Typography';
import SelectableAnimation from './SelectableAnimation';
import useIsMobile from 'hooks/useIsMobile';

import { getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import { EnumTextType } from 'theme';
import bullsEyeIcon from './img/bullseye.svg';

const Container = styled(Flex).attrs({ column: true, end: true, bottom: true })`
  position: relative;
  width: 100vw;

  ${mediaLgUp} {
    height: ${getVwLg('820px')};
  }
`;

export const exercises: string[] = [
  'Двигайте глазами \nиз стороны в сторону',
  'Двигайте глазами \nвверх‑вниз',
  'Двигайте глазами \nпо кругу',
  'Двигайте глазами \nпо диагонали',
  'Широко откройте и \nзажмурьте глаза',
  'Посмотрите \nна кончик носа',
];

const listCardTheme = {
  ...listCardDefaultTheme,
  basis: 278,
  height: 146,
};

const StyledAnimation = styled(SelectableAnimation)`
  position: absolute;
  left: 0;
  top: 0;
`;

const SyledTextBlock = styled(StickyTextBlock)`
  background: none;
`;

const BullsEyeIcon = styled.img.attrs({ src: bullsEyeIcon })`
  width: ${getVwSm('32px')};
  height: ${getVwSm('32px')};
  margin-right: ${getVwSm('8px')};

  ${mediaLgUp} {
    width: ${getVwLg('64px')};
    height: ${getVwLg('64px')};
    margin-right: ${getVwLg('16px')};
    opacity: 0.6;
  }
`;

const StyledCard = styled(ListCard).attrs({
  mt: { sm: 20, lg: 68 },
  ml: 0,
})`
  ${mediaLgDown} {
    white-space: normal;
  }
`;

const ExercisesBlock = (): JSX.Element => {
  const isMobile = useIsMobile();

  return (
    <Container>
      {!isMobile && <StyledAnimation />}

      <SyledTextBlock>
        <StyledTitle>Упражнения для глаз</StyledTitle>

        <Box mb={{ sm: 24, lg: 0 }}>
          <HandText textType={{ sm: EnumTextType.bodyL, lg: EnumTextType.body }}>
            Комплекс специальных упражнений способствует укреплению глазных&nbsp;мышц.
          </HandText>
        </Box>

        {isMobile && (
          <HorizontalScrollable>
            {exercises.map((exercises, i) => (
              <ListCard key={exercises} theme={{ ...listCardTheme }}>
                <Box mb={8}>
                  <ListBullet>{i + 1}</ListBullet>
                </Box>
                <HandText textType={EnumTextType.bodyL}>{exercises}</HandText>
              </ListCard>
            ))}
          </HorizontalScrollable>
        )}

        <StyledCard>
          <Flex middle mb={12}>
            <BullsEyeIcon />
            <HandText textType={{ sm: EnumTextType.bodyL, lg: EnumTextType.body }}>«Метка на стекле».</HandText>
          </Flex>

          <Box mb={12}>
            <Text>
              Находясь в&nbsp;30‑35 сантиметрах от&nbsp;оконного стекла, прикрепите к&nbsp;нему на&nbsp;уровне глаз
              круглую цветную метку диаметром 3‑5&nbsp;миллиметров, затем вдали от&nbsp;линии взора, проходящего через
              метку, наметьте для зрительной фиксации какой‑либо объект.
            </Text>
          </Box>

          <Text>
            Смотрите обоими глазами на&nbsp;эту метку 1‑2&nbsp;секунды, затем переведите&nbsp;взор на&nbsp;намеченный
            объект в&nbsp;течение 1‑2&nbsp;секунд, после чего поочередно переводите взор то&nbsp;на&nbsp;метку,
            то&nbsp;на&nbsp;объект в&nbsp;течение 5&nbsp;минут. Повторяйте упражнение систематически с&nbsp;перерывами
            в&nbsp;10‑15&nbsp;дней.
          </Text>
        </StyledCard>
      </SyledTextBlock>
    </Container>
  );
};

export default ExercisesBlock;
