import Flex from 'Components/Flex';
import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  StyledTitle,
  StickyTextBlock,
  HorizontalScrollable,
  ListCard,
  ListBullet,
  Divider,
  HideContainerLg,
  HideContainerSm,
} from './styled';
import { Text } from 'Components/Typography';
import { NBSP } from '../../constants';
import { getVwLg } from 'theme/helpers/css';
import Box from 'Components/Box';
import useScrollListener from 'hooks/useScrollListener';
import useIsMobile from 'hooks/useIsMobile';
import { throttle } from 'lodash';

const Container = styled(Flex).attrs({
  column: true,
  end: true,
  bottom: true,
  px: { lg: 92 },
})`
  //replace with real height
  height: 2800px;
  width: 100vw;
`;

const List = styled(Box).attrs({ as: 'ul', mt: 48 })`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: ${getVwLg('18px')};

  ${ListBullet} {
    margin-right: ${getVwLg('16px')};
  }
`;

const advices: string[] = [
  `Обеспечьте \nравномерное \nи${NBSP}достаточное \nосвещение.`,
  `Не${NBSP}направляйте \nлампы на${NBSP}монитор \nи${NBSP}глаза.`,
  `Поставьте монитор \nна${NBSP}высоту 65-70${NBSP}см \nот${NBSP}пола.`,
  `Расположите монитор \nна${NBSP}15-20${NBSP}см ниже \nуровня глаз, чтобы \nсузить глазную щель.`,
  `Расстояние от${NBSP}глаз \nдо${NBSP}монитора должно \nбыть 50-70${NBSP}см.`,
  `Отрегулируйте стул так, \nчтобы вашим стопам было \nудобно твёрдо стоять \nна${NBSP}полу, колени были согнуты \nпод${NBSP}углом 90${NBSP}градусов.`,
];

const WorkingPlaceBlock = (): JSX.Element => {
  const isMobile = useIsMobile();
  const animated = useRef<HTMLDivElement>();

  const setOpacityStyle = (dec: number) => {
    animated.current.style.opacity = dec.toString();
  };

  const handleScroll = () => {
    if (animated.current) {
      const { offsetHeight, offsetTop, parentElement } = animated.current;
      const { innerHeight } = window;
      const animationLength = innerHeight + offsetHeight;
      const played = offsetTop + offsetHeight - (parentElement.offsetHeight - animationLength);

      if (played <= 0) setOpacityStyle(1);
      else if (played >= animationLength) setOpacityStyle(0);
      else setOpacityStyle(1 - played / animationLength);
    }
  };

  useScrollListener(throttle(handleScroll, 50), isMobile);

  return (
    <Container>
      <StickyTextBlock ref={animated} mb={{ sm: 80, lg: 0 }}>
        <StyledTitle>Организация рабочего пространства</StyledTitle>

        <HideContainerLg>
          <Divider />

          <HorizontalScrollable>
            {advices.map((advice, i) => (
              <ListCard key={advice}>
                <Box mb={8}>
                  <ListBullet>{i + 1}</ListBullet>
                </Box>
                <Text>{advice}</Text>
              </ListCard>
            ))}
          </HorizontalScrollable>
        </HideContainerLg>

        <HideContainerSm>
          <List>
            {advices.map((advice, i) => (
              <Flex key={advice}>
                <ListBullet>{i + 1}</ListBullet>
                <Text>{advice}</Text>
              </Flex>
            ))}
          </List>
        </HideContainerSm>
      </StickyTextBlock>
    </Container>
  );
};

export default WorkingPlaceBlock;
