import Flex from 'Components/Flex';
import Box from 'Components/Flex';
import React from 'react';
import styled from 'styled-components';
import { StyledTitle, StickyTextBlock } from './styled';
import { Text, HandText } from 'Components/Typography';
import { EnumTextType } from 'theme';

const Container = styled(Flex).attrs({
  column: true,
  end: true,
  bottom: true,
  px: { lg: 92 },
})`
  //replace with real height
  height: 2000px;
  width: 100vw;
`;

const Rule2020Block = (): JSX.Element => (
  <Container>
    <StickyTextBlock px={0} pl={{ sm: 15, lg: 78 }} pr={{ sm: 15, lg: 66 }}>
      <Box mb={{ sm: 12, lg: 0 }}>
        <StyledTitle>Правило 20/20</StyledTitle>
      </Box>

      <Box mb={{ sm: 16, lg: 38 }}>
        <HandText textType={{ sm: EnumTextType.bodyL, lg: EnumTextType.body }}>
          Даже если у&nbsp;вас не&nbsp;болят глаза от&nbsp;экрана,&nbsp;&mdash; каждые 20&nbsp;минут делайте
          20-секундный перерыв.
        </HandText>
      </Box>

      <Box mb={{ sm: 8, lg: 20 }}>
        <Text>
          Посмотрите вдаль на&nbsp;шесть метров, например, на&nbsp;дерево за&nbsp;окном. Это поможет сохранить
          правильную работу аккомодационной мышцы.
        </Text>
      </Box>

      <Text>
        Американская Оптометрическая Ассоциация и&nbsp;Американская Академия Офтальмологии рекомендуют этот способ
        снятия напряжения глаз.
      </Text>
    </StickyTextBlock>
  </Container>
);

export default Rule2020Block;
