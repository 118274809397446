import Lottie, { AnimationItem } from 'lottie-web';
import { MutableRefObject, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAnimation = (animationData: any, containerRef: MutableRefObject<HTMLDivElement>) => {
  const [instance, setInstance] = useState<AnimationItem>({} as AnimationItem);

  const createAnimation = () =>
    Lottie.loadAnimation({
      container: containerRef.current,
      animationData,
      renderer: 'svg',
      autoplay: false,
      loop: false,
    });

  useEffect(() => {
    const instance = createAnimation();
    setInstance(instance);
    return () => instance.destroy();
  }, [animationData]);

  return instance;
};

export default useAnimation;
