import Box from 'Components/Box';
import Flex from 'Components/Flex';
import { HandText, Title } from 'Components/Typography';
import styled from 'styled-components';
import { EnumTextType } from 'theme';
import colors from 'theme/colors';
import { EnumScreens, getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import blockBg from './img/block_bg.svg';

export const StyledTitle = styled(Title).attrs({
  lvl: 2,
  textType: EnumTextType.h3,
})`
  ${mediaLgDown} {
    font-size: ${getVwSm('36px')};
    line-height: ${getVwSm('36px')};
  }
`;

export const StickyTextBlock = styled(Flex).attrs(props => ({
  px: { sm: 15, lg: 66 },
  py: { sm: 15, lg: 72 },
  column: true,
  center: true,
  ...props,
}))`
  position: sticky;
  bottom: 0;

  ${mediaLgUp} {
    width: ${getVwLg('682px')};
    height: 100vh;
    min-height: ${getVwLg('800px')};
    background: center / contain url('${blockBg}') no-repeat;
  }

  ${mediaLgDown} {
    background-color: ${colors.paleMint60};
    bottom: ${getVwSm('80px')};
  }
`;

export const HorizontalScrollable = styled(Flex).attrs({
  px: 15,
  mx: -15,
})`
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export interface IListCardTheme {
  basis?: number;
  height: number;
  bgColor: string;
  body: any;
}

export const listCardDefaultTheme: IListCardTheme = {
  height: 186,
  bgColor: colors.paleMint,
  body: { color: colors.mint },
};

export const ListCard = styled(Flex).attrs(props => ({
  column: true,
  px: { sm: 15, lg: 0 },
  py: { sm: 15, lg: 0 },
  ml: { sm: 15, lg: 0 },
  theme: listCardDefaultTheme,
  ...props,
}))`
  min-width: ${getVwSm('176px')};
  flex: 0 0 ${({ theme }) => (theme.basis ? getVwSm(`${theme.basis}px`) : 'auto')};
  height: ${props => getVwSm(`${props.theme.height}px`)};
  border-radius: ${getVwSm('22px')};
  border: ${getVwSm('3px')} ${props => props.theme.body?.color} solid;
  background-color: ${props => props.theme.bgColor};

  &:first-child {
    margin-left: 0;
  }

  ${mediaLgDown} {
    white-space: pre;
  }

  ${mediaLgUp} {
    width: 100%;
    min-width: unset;
    border: none;
  }
`;

export const ListBullet = styled(HandText).attrs({
  align: 'center',
})`
  width: ${getVwSm('24px')};
  height: ${getVwSm('24px')};

  border-radius: 50%;
  background-color: ${colors.mint};
  color: ${colors.paleMint};

  line-height: ${getVwSm('20px')};
  flex-shrink: 0;

  ${mediaLgUp} {
    width: ${getVwLg('32px')};
    height: ${getVwLg('32px')};
    font-size: ${getVwLg('28px')};
    line-height: ${getVwLg('23px')};
  }
`;

export const Divider = styled(Box).attrs({
  my: 8,
})`
  height: ${getVwSm('3px')};
  background-color: ${colors.mint};
`;

export const HideContainerSm = styled(Box).attrs({ hideBefore: EnumScreens.sm })``;
export const HideContainerLg = styled(Box).attrs({ hideAfter: EnumScreens.lg })``;
