import React from 'react';
import Icon from './Icon';

const IconMoon: React.FC<{ color: string; className?: string }> = ({ color, className }) => (
  <Icon viewBox="0 0 64 64" className={className}>
    <path
      d="M14.8968 47.9928L20.4168 47.4109C26.7774 46.1174 31.9846 40.7877 34.438 34.7337C37.6521 26.7927 34.3878 16.6765 28.3899 12.1535C22.392 7.63057 29.0063 7.3388 35.8878 9.52939C42.7694 11.72 58.2543 23.308 50.618 41.7863C43.7693 58.3671 24.0549 59.949 12.1438 50.0756"
      stroke={color}
      strokeWidth="3.0016"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconMoon;
