import React from 'react';
import Flex from 'Components/Flex';
import useAnimation from 'hooks/useAnimationNew';
import styled, { css } from 'styled-components';
import { getVwLg, mediaLgUp } from 'theme/helpers/css';
import { useEffect } from 'react';
import { useState } from 'react';
import { animations, EnumAnimations, animationDescs, buttonIcons } from './animationData';
import Box from 'Components/Box';
import { HandText } from 'Components/Typography';
import tooltipTip from './img/tooltip_tip.svg';
import { exercises } from './ExercisesBlock';

const Container = styled(Flex).attrs({
  center: true,
})`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const AnimationContainer = styled(Flex).attrs({
  center: true,
  middle: true,
})`
  z-index: 0;

  ${mediaLgUp} {
    width: 100%;

    svg {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const ButtonsContainer = styled(Box)`
  display: grid;
  grid-template: repeat(2, max-content) / repeat(3, max-content);
  grid-gap: ${getVwLg('32px')} ${getVwLg('32px')};

  position: absolute;
  left: ${getVwLg('282px')};
  top: 30vh;
`;

const Tooltip = styled(Box).attrs(props => ({
  bgColor: props.theme.body?.color,
  px: 12,
  py: 8,
  mt: -34,
  ml: -34,
  ...props,
}))`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${getVwLg('16px')};
  transform: translateY(-100%) rotate(-5deg);

  ${HandText} {
    color: ${props => props.theme.bgColor}!important;
    white-space: pre;
  }

  &::before {
    width: ${getVwLg('60px')};
    height: ${getVwLg('20px')};
    position: absolute;
    bottom: 1px;
    left: ${getVwLg('32px')};
    transform: translateY(100%);
    background: center / contain url('${tooltipTip}');
    content: '';
  }

  visibility: hidden;
`;

const ButtonContainer = styled.div`
  position: relative;
`;

const IconButton = styled.img<{ active?: boolean }>`
  width: ${getVwLg('64px')};
  height: ${getVwLg('64px')};
  opacity: ${props => (props.active ? 1 : 0.6)};
  cursor: pointer;

  &:hover ~ ${Tooltip} {
    visibility: visible !important;
  }
`;

const AnimationBlock: React.FC<{ className?: string }> = ({ className }) => {
  const [currentAnim, setCurrentAnim] = useState(EnumAnimations.DEFAULT);
  const [queued, setQueued] = useState();

  const [container, instance] = useAnimation({
    animationData: animations[currentAnim],
    loop: true,
  });

  const segments = [
    [0, 10],
    [10, 30],
    [30, 40],
  ];

  const switchAnim = key => {
    setQueued(key);
    instance.loop = false;
    instance.playSegments(segments[2], false);
    instance.addEventListener('complete', () => setCurrentAnim(key));
  };

  useEffect(() => {
    if (instance?.isLoaded) {
      instance.playSegments([segments[0], segments[1]], true);
    }
  }, [instance]);

  return (
    <Container className={className}>
      <AnimationContainer ref={container} />

      <ButtonsContainer>
        {Object.entries(buttonIcons).map(([k, iconUrl]) => (
          <ButtonContainer key={k}>
            <IconButton onClick={() => switchAnim(k)} active={k === queued} src={iconUrl} />
            <Tooltip>
              <HandText align="center">{exercises[k - 1]}</HandText>
            </Tooltip>
          </ButtonContainer>
        ))}
      </ButtonsContainer>
    </Container>
  );
};

export default AnimationBlock;
