import React from 'react';
import Icon from './Icon';

const IconSun: React.FC<{ color: string; className?: string }> = ({ color, className }) => (
  <Icon viewBox="0 0 64 64" className={className}>
    <path
      d="M33.8115 47.5368C54.9149 44.21 50.5367 16.4377 35.1744 15.0568C19.812 13.6759 15.5906 30.0298 16.7181 35.0213C17.8456 40.0128 20.2909 48.5776 33.6118 45.4452"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.5802 41.5992C41.4994 37.426 46.3307 27.2858 42.7523 31.5237C39.1738 35.7615 28.8163 44.9611 30.3846 42.3682C31.9529 39.7754 45.3362 25.0345 42.5323 26.7398C39.7284 28.445 24.4981 44.4408 26.5538 40.4841C28.6095 36.5273 44.3778 21.2451 41.3577 22.8461C38.3378 24.4383 20.0767 41.331 23.1332 37.6818C26.1897 34.0327 40.69 18.5391 37.1706 20.8351C33.6512 23.1311 18.3652 36.1871 21.0709 33.2646C23.7766 30.3421 33.4779 19.7826 30.1168 21.574C26.7558 23.3653 19.3739 28.2092 20.3673 26.7134C21.4934 25.1958 22.688 23.7334 23.9682 22.3356"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.4545 18.5091C54.6053 19.8255 52.5642 20.845 50.4024 21.5435"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.3771 62.5C36.6344 60.3454 36.2379 58.0974 36.1855 55.8174"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7072 9.18259C32.9644 7.02802 32.5679 4.77999 32.5156 2.5"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2067 56.334C18.0441 52.1583 20.5769 50.4084 20.5769 50.4084"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.99937 37.8837C10.125 35.6089 12.6075 35.8011 12.6075 35.8011"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1539 21.5701C12.8115 19.6324 10.8924 18.1668 9.21094 16.947"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.1699 46.9917C47.6427 48.644 49.2246 50.1946 50.889 51.6512"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.2236 37.6052C55.8345 37.7592 53.4455 37.6582 51.0742 37.3027"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="4.0021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconSun;
