import Flex from 'Components/Flex';
import React from 'react';
import WorkingPlaceBlock from './WorkingPlaceBlock';
import Rule2020Block from './Rule2020Block';
import EyeDropsBlock from './EyeDropsBlock';
import ExercisesBlock from './ExercisesBlock';
import { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import Animation from './Animation';
import Disclaimer, { EnumDisclaimers, DisclaimerWrapper } from 'Components/Disclaimer';
import Logo from 'Components/Logo';

const Advices = (): JSX.Element => {
  const { bgColor } = useContext(ThemeContext);

  return (
    <Flex column bgColor={bgColor}>
      <Logo />
      <Animation>
        <WorkingPlaceBlock />
        <Rule2020Block />
        <Disclaimer type={EnumDisclaimers.NOT_DOCTOR} />
      </Animation>
      <EyeDropsBlock />
      <ExercisesBlock />
    </Flex>
  );
};

export default Advices;
