import React from 'react';
import Layout from 'Containers/Layout';
import Navbar, { NavbarPages } from 'Components/Navbar';
import Footer from 'Components/Footer';
import Advices from 'Containers/Advices';
import colors from 'theme/colors';

const advicesTheme = {
  transition: colors.mint,
  bgColor: colors.paleMint,
  body: {
    color: colors.mint,
  },
  footer: {
    bgColor: colors.mint,
    color: colors.paleMint,
  },
  disclaimer: {
    bgColor: colors.mint,
    color: colors.paleMint,
  },
};

const AdvicesPage = (): JSX.Element => (
  <Layout theme={advicesTheme}>
    <Navbar page={NavbarPages.ADVICES} />
    <Advices />
    <Footer theme={advicesTheme.footer} />
  </Layout>
);

export default AdvicesPage;
