import Flex from 'Components/Flex';
import Box from 'Components/Box';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { StyledTitle, StickyTextBlock, Divider, HorizontalScrollable, ListCard, IListCardTheme } from './styled';
import { Text, HandText } from 'Components/Typography';
import colors from 'theme/colors';
import { ExternalLink, Link } from 'Components/Link';
import { EnumTextType } from 'theme';
import { getVwSm, getVwLg, mediaLgUp } from 'theme/helpers/css';
import useIsMobile from 'hooks/useIsMobile';
import bg from './img/eye_drops_bg.png';
import IconMoon from 'Components/Icons/IconMoon';
import IconSun from 'Components/Icons/IconSun';
import Disclaimer, { EnumDisclaimers, DisclaimerWrapper } from 'Components/Disclaimer';
import { URLs, NBSP } from '../../constants';

const Container = styled(Flex).attrs({
  px: { lg: 160 },
  py: { lg: 183 },
  pb: { sm: 80 },
  column: true,
  end: true,
  bottom: true,
})`
  width: 100vw;
  position: relative;

  ${mediaLgUp} {
    height: ${getVwLg('1080px')};
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background: center / cover url('${bg}') no-repeat;

    & > * {
      flex: 0 0 ${getVwLg('500px')};
    }

    & > :first-child {
      align-self: center;
    }
  }
`;

const nightTheme = {
  basis: 320,
  height: 380,
  bgColor: colors.blue,
  body: { color: colors.moonYellow },
};

const dayTheme = {
  ...nightTheme,
  bgColor: colors.paleBlue,
  body: { color: colors.blue },
};

const StyledIconSun = styled.svg.attrs(props => ({
  color: props.theme.body?.color,
  ...props,
}))`
  width: ${getVwSm('32px')};
  height: ${getVwSm('32px')};

  ${mediaLgUp} {
    width: ${getVwLg('64px')};
    height: ${getVwLg('64px')};
  }
`;

const cardItems = [
  {
    icon: <StyledIconSun as={IconMoon} />,
    title: 'Ночью',
    subtitle: `Обеспечьте полноценный отдых${NBSP}глазам.`,
    content: (
      <>
        <Text>
          Для этого есть специальные капли, курс закапывания которых восстанавливает нормальное состояние глазной мышцы,
          способствует устранению симптомов зрительного утомления и&nbsp;торможению прогрессирования близорукости.
          Достаточно закапывать препарат на&nbsp;ночь. <ExternalLink href={URLs.makeAppointment}>Узнайте</ExternalLink>{' '}
          у врача-офтальмолога подробнее.
        </Text>
      </>
    ),
    theme: nightTheme,
  },
  {
    icon: <StyledIconSun as={IconSun} />,
    title: 'Днём',
    subtitle: `Позаботьтесь о защите и${NBSP}увлажнении глаз.`,
    content: (
      <>
        <Text>
          Используйте глазные капли для снятия усталости глаз от&nbsp;компьютера{' '}
          <Link to={URLs.nightdrops}>Офтолик&sup1;</Link>. Это слезозаменитель. Капли увлажняют глаза и&nbsp;защищают
          слезную пленку, предотвращают повреждение поверхности глаза.
        </Text>

        <Box mt={{ sm: 8, lg: 20 }}>
          <Text textType={EnumTextType.note}>¹Регистрационный номер препарата: ЛСР-001359/08</Text>
        </Box>
      </>
    ),
    theme: dayTheme,
  },
];

const ThemedListCard: React.FC<{ theme: IListCardTheme }> = ({ children, theme }): JSX.Element => (
  <ThemeProvider theme={theme}>
    <ListCard style={{ whiteSpace: 'unset' }}>{children}</ListCard>
  </ThemeProvider>
);

const EyeDropsBlock = (): JSX.Element => {
  const isMobile = useIsMobile();

  return (
    <Container>
      {isMobile ? (
        <StickyTextBlock>
          <StyledTitle>Капли для глаз</StyledTitle>

          <Divider />

          <HorizontalScrollable>
            {cardItems.map(item => (
              <ThemedListCard key={item.title} theme={item.theme}>
                <Flex middle mb={8}>
                  {item.icon}
                  <StyledTitle>{item.title}</StyledTitle>
                </Flex>

                <Box mb={8}>
                  <HandText textType={EnumTextType.bodyL}>{item.subtitle}</HandText>
                </Box>

                {item.content}
              </ThemedListCard>
            ))}
          </HorizontalScrollable>
        </StickyTextBlock>
      ) : (
        <>
          <Flex column center>
            <Flex middle mb={38} wrap>
              {cardItems[0].icon}
              <StyledTitle>{cardItems[0].title}</StyledTitle>
              <HandText>{cardItems[0].subtitle}</HandText>
            </Flex>
            {cardItems[0].content}
          </Flex>

          <Flex column>
            <StyledTitle>Капли для глаз</StyledTitle>

            <Flex middle mt={64} mb={38} wrap>
              {cardItems[1].icon}
              <StyledTitle>{cardItems[1].title}</StyledTitle>
              <HandText>{cardItems[1].subtitle}</HandText>
            </Flex>
            {cardItems[1].content}
          </Flex>
        </>
      )}
      <DisclaimerWrapper>
        <Disclaimer type={EnumDisclaimers.BEFORE_USING} />
      </DisclaimerWrapper>
    </Container>
  );
};

export default EyeDropsBlock;
